import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import '../../assets/scss/signup.scss';

import SignUpForm from '../../components/SignUp/';


const SignUp = (props) => {
  return (
    <div id="main-wrapper" className="signup">
      <Header />
      <div className="main-content">
        <SignUpForm />
      </div>
      <Footer color="light"/>
    </div>
  );
}


export default SignUp;
