import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import SupportForm from '../../components/Support';

import '../../assets/scss/support.scss';

function Support() {
  return (
    <div id="main-wrapper" className="support">
      <Header />
      <div className="main-content">
        <section className="hns-section">
          <div className="inner">
            <p>You can call or email us 24/7/365</p>
            <h1>Support</h1>
            <hr />
            <SupportForm /> 
            <div data-ref="form"> </div>
          </div>
        </section>
      </div>
      <Footer color="light"/>
    </div>
  )
}




export default Support;
