import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
//import BrowserSupport, { detectBrowser } from './utils/BrowserSupport';
import './index.css';
import App from './App';
import AppWithLogin from './AppWithLogin';
import * as serviceWorker from './serviceWorker';


const isTestServer = process.env.REACT_APP_ENV === 'testing';

// Internet Explorer 6-11
let isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
let isEdge = !isIE && !!window.StyleMedia;

// const minBrowserVersions = {
//   chrome: '29', 
//   edge: '15',
//   firefox: '32', 
//   ie: '11',
//   opera: '30',
//   safari: '9.0',
// }

class Root extends PureComponent {
  constructor() {
    super();
    
    this.state = {
      supported: '',
      //browser: detectBrowser(),
      loadApp: ''
    }
  }

  componentDidMount() {
    //this.setState({ browser: detectBrowser() })
  }

  render () {
    if (isTestServer ) {
      return (
        <div className={`${isIE ?  "ie" : null} ${isEdge ?  "edge" : null}`} >
          {/* <BrowserSupport supported={minBrowserVersions} /> */}
          <AppWithLogin />
        </div>
      )
    } else {
      return (
        <div className={`${isIE ?  "ie" : null} ${isEdge ?  "edge" : null}`} >
          {/* <BrowserSupport supported={minBrowserVersions} /> */}
          <App />
        </div>
        
      ) 
    }
  }
}
ReactDOM.render(<Root />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();