import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import '../../assets/scss/policies.scss';
import policies from '../../assets/images/policies.svg';

import PrivacyPolicy from '../../assets/documents/ImagineInternet_PP_2019.pdf';
import AcceptableUse from '../../assets/documents/ImagineInternet_AUP_2019.pdf';
import NetworkDisclosure from '../../assets/documents/ImagineInternet_NMD_2019.pdf';
import TermsOfService from '../../assets/documents/ImagineInternet_TOS_2019.pdf';


const Policies = () => (
  <div id="main-wrapper" className="policies">
    <Header />
    <div className="main-content">
      <section className="hns-section">
        <div className="inner">
          <h1>Policies</h1>
          <hr />
          <h6>
            <a href={AcceptableUse} target="_blank" rel="noopener noreferrer">Acceptable use</a>
          </h6>
          <h6>
            <a href={NetworkDisclosure} target="_blank"  rel="noopener noreferrer">Network disclosure</a>
          </h6>
          <h6>
            <a href={PrivacyPolicy} rel="noopener noreferrer" target="_blank">Privacy policy</a>
          </h6>
          <h6>
            <a href={TermsOfService} target="_blank"  rel="noopener noreferrer">Terms of service</a>
          </h6>

          <img src={policies} alt="Policies - contract" width="317" height="257" />
        </div>
      </section>
    </div>
    <Footer color="light"/>
  </div>
);

export default Policies;


