import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, withRouter, Switch } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop.js';

import Home from './screens/Home';
import Support from './screens/Support';
import SignUp from './screens/SignUp';
import Policies from './screens/Policies';
import FourOhFour from './screens/404';

import logo from './assets/images/logo.svg';
import './assets/scss/auth.scss';
import '../src/assets/scss/index.scss';


const AuthCentralState = {
  isAuthenticated: false,
  authenticate(callback) {
    this.isAuthenticated = true;
    setTimeout(callback, 300);
  }
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      username: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({
      [evt.target.id]: evt.target.value,
    })
  }

  handleError(error) {
    alert(error);
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const { username, password } = this.state;
    const user = { username, password }

    if (username === 'open' && password === 'sesame') {
      this.handleSave(user);
    } else {
      this.handleError("Invalid username/password combination");
    }
  }

  handleSave(user) {
    localStorage.setItem('user', JSON.stringify(user)) 

    AuthCentralState.authenticate(() => {
      this.setState(() => ({
        redirectToReferrer: true
      }));
    });
  }

  validateForm() {
    const { username, password } = this.state;

    return (username && username.length > 0) &&
      (password && password.length > 0);
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      this.props.history.push(from.pathname);
    }

    return (
      <div id="main-wrapper" className="signin">
        <div className="main-content">
          <section className="hns-section">
            <div className="inner">
              <div className="logo-container">
                <a href="/" className="logo">
                    <img src={logo} alt="imagine" width="140" height="56"/>
                </a>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="cols">
                  <input
                    id="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    value={this.state.username}
                    required
                    onChange={this.handleChange}
                    autoComplete="username"
                  />
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    onChange={this.handleChange}
                    autoComplete="current-password"
                  />
                </div>
                <div className="cols">
                  <button
                    className="btn btn-blue"
                    type="submit"
                    disabled={!this.validateForm()}
                  >
                    Login
                  </button>
                </div>
            </form>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    AuthCentralState.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route 
                path="/login" 
                component={withRouter(Login)}
              />
              <ProtectedRoute
                path="/"
                exact
                component={Home}
              />
              <ProtectedRoute
                path="/policies"
                exact
                component={Policies}
              />
              <ProtectedRoute
                path="/support"
                exact
                component={Support}
              />
              <ProtectedRoute
                path="/signup"
                exact
                component={SignUp}
              />
              <ProtectedRoute
                path="*"
                exact
                component={FourOhFour}
              />
            </Switch>  
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
