import React, { Component } from 'react';
import axios from 'axios';
import cx from 'classnames';

import AddressChecker from '../AddressChecker';

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailResponse: '',
      message: '',
      givenName: '',
      familyName: '',
      email: '',
      phoneNumber: '',
      addressFirst: '',
      addressSecond: '',
      city: '',
      usState: 'NY',
      zipCode: '',
      country: 'United States',
      notes: '',
      formError: [],
      buildingSelected: false,
      matchingBuilding: false,
      calendarUrl: null
    };
  }

  componentDidMount() {
    const head = document.querySelector('head');

    const calendlyCSS = document.createElement('link');
    calendlyCSS.setAttribute(
      'href',
      'https://calendly.com/assets/external/widget.css'
    );
    calendlyCSS.setAttribute('rel', 'stylesheet');
    head.appendChild(calendlyCSS);

    const calendlyScript = document.createElement('script');
    calendlyScript.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(calendlyScript);

   
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  getFormError = formData => {
    const error = [];
    const {
      givenName,
      familyName,
      email,
      phoneNumber,
      addressFirst,
      city,
      usState,
      zipCode
    } = formData;
    const isEmailValid = email => /\S+@\S+\.\S+/.test(email);
    const isphoneNumberValid = phoneNumber =>
      /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phoneNumber);

    if (!givenName.length) {
      error.push({
        key: 'givenName',
        error: 'Given Name cannot be empty.'
      });
    }

    if (!familyName.length) {
      error.push({
        key: 'familyName',
        error: 'Family Name cannot be empty.'
      });
    }

    if (!email.length) {
      error.push({
        key: 'email',
        error: 'Email cannot be empty.'
      });
    }

    if (!isEmailValid(email)) {
      error.push({
        key: 'email',
        error: 'Please enter a valid email address.'
      });
    }

    if (!phoneNumber.length) {
      error.push({
        key: 'phoneNumber',
        error: 'Phone Number cannot be empty.'
      });
    }

    if (!isphoneNumberValid(phoneNumber)) {
      error.push({
        key: 'phoneNumber',
        error: 'Please check your phone number for typos.'
      });
    }

    if (!addressFirst.length) {
      error.push({
        key: 'addressFirst',
        error: 'Street Address cannot be empty.'
      });
    }

    if (!city.length) {
      error.push({
        key: 'city',
        error: 'City cannot be empty.'
      });
    }

    if (!usState.length) {
      error.push({
        key: 'usState',
        error: 'State cannot be empty.'
      });
    }

    if (!zipCode.length) {
      error.push({
        key: 'zipCode',
        error: 'Zip Code cannot be empty.'
      });
    }

    return error;
  };

  get givenNameFieldError() {
    const { formError } = this.state;
    const givenNameErrors = formError.filter(err => err.key === 'givenName');
    if (givenNameErrors.length) {
      return givenNameErrors[0].error;
    }

    return null;
  }

  get familyNameFieldError() {
    const { formError } = this.state;
    const familyNameErrors = formError.filter(err => err.key === 'familyName');
    if (familyNameErrors.length) {
      return familyNameErrors[0].error;
    }

    return null;
  }

  get emailFieldError() {
    const { formError } = this.state;
    const emailErrors = formError.filter(err => err.key === 'email');
    if (emailErrors.length) {
      return emailErrors[0].error;
    }

    return null;
  }

  get phoneNumberFieldError() {
    const { formError } = this.state;
    const phoneNumberErrors = formError.filter(
      err => err.key === 'phoneNumber'
    );
    if (phoneNumberErrors.length) {
      return phoneNumberErrors[0].error;
    }

    return null;
  }

  get addressFirstFieldError() {
    const { formError } = this.state;
    const addressFirstErrors = formError.filter(
      err => err.key === 'addressFirst'
    );
    if (addressFirstErrors.length) {
      return addressFirstErrors[0].error;
    }

    return null;
  }

  get cityFieldError() {
    const { formError } = this.state;
    const cityErrors = formError.filter(err => err.key === 'city');
    if (cityErrors.length) {
      return cityErrors[0].error;
    }

    return null;
  }

  get usStateFieldError() {
    const { formError } = this.state;
    const usStateErrors = formError.filter(err => err.key === 'usState');
    if (usStateErrors.length) {
      return usStateErrors[0].error;
    }

    return null;
  }

  get zipCodeFieldError() {
    const { formError } = this.state;
    const zipCodeErrors = formError.filter(err => err.key === 'zipCode');
    if (zipCodeErrors.length) {
      return zipCodeErrors[0].error;
    }

    return null;
  }

  handlePreSubmit = event => {
    event.preventDefault();
    const {
      givenName,
      familyName,
      email,
      phoneNumber,
      addressFirst,
      addressSecond,
      city,
      usState,
      zipCode,
      country,
      notes,
      calendarUrl
    } = this.state;

    const formData = {
      givenName,
      familyName,
      email,
      phoneNumber,
      addressFirst,
      addressSecond,
      city,
      usState,
      zipCode,
      country,
      notes
    };

    const formError = this.getFormError(formData);

    if (!formError.length) {
      this.setState({ formError: [] });
      this.handleSubmit(formData, calendarUrl);
    } else {
      this.setState({ formError });
    }
  };

  async handleSubmit(formData, calendarUrl = null) {
    const {
      givenName,
      familyName,
      email,
      phoneNumber,
      addressFirst
    } = formData;

    const isCalendly = this.state.matchingBuilding;

    await axios
      .post('/api/mail', {
        ...formData,
        type: isCalendly ? 'signup-calendly' : 'signup',
        countryCode: '+1',
        country: 'United States'
      })
      .then(res => {
        if (isCalendly) {
          // Change the mapping here if the order or amount of the freeform fields in Calendly are changing
          // Note: spaces are encoded as %20
          const calendlyfieldsMapping = {
            name: `${givenName}%20${familyName}`,
            email: email,
            a1: `+1%20${phoneNumber}`,
            a2: addressFirst
          };

          const mappedFields = Object.keys(calendlyfieldsMapping).reduce(
            (prev, key, index) =>
              `${prev}${index === 0 ? '?' : '&'}${key}=${
                calendlyfieldsMapping[key]
              }`,
            ''
          );

          // eslint-disable-next-line
          Calendly.initPopupWidget({url:`${calendarUrl}${mappedFields}`})
        }

        this.setState({
          emailResponse: res.data.success,
          message: res.data.message
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          emailResponse: err.data.success,
          message: err.data.message
        });
      });
  }

  render() {
    return (
      <div>
        {this.state.emailResponse ? (
          <section className="hns-section thanks">
            <div className="inner">
              <div className='form-container'>
                <h5>No contracts</h5>
                <h1>Sign up</h1>
                <hr />
                { this.state.matchingBuilding === false ? (
                  <div className='thank-you'>
                    <h3>Thank for you for your request.</h3>
                    <p>A member of our sales team will reach out as soon as possible. </p>
                    <p>Have a great day! </p>
                  </div>
                ) : (
                  <div className='thank-you'>
                    <h3>Thank you for Signing up for Imagine Internet!</h3>
                    <p>
                      A member of our Internet team will reach out as soon as possible
                      to get you setup.
                    </p>
                    <p>
                      Call us at 1-888-982-1220 if you have questions
                      about your order.
                    </p>
                    <p>Have a great day! </p>
                  </div>
                ) }
                </div>
              </div>
          </section>
        ) : (
          <section className="hns-section">
            <div className="inner">
              <div className='form-container'>
                <h5>No contracts</h5>
                <h1>Sign up</h1>
                <hr />
                <form className='small-form'>
                  <p>Installation fee $45</p>
                  <div className="price">
                    <span><span>$</span>&nbsp;45<small>/month</small></span>
                  </div>
                  <div className="form">
                    <ul className='container'>
                      <li className='half full-mobile'>
                        {this.givenNameFieldError && (
                          <span className='error bottom'>
                            {this.givenNameFieldError}
                          </span>
                        )}
                        <div className="border"></div>
                        <input
                          id='givenName'
                          className='form-control'
                          type='text'
                          placeholder='First Name'
                          value={this.state.givenName}
                          onChange={this.handleChange('givenName')}
                          autoComplete='given-name'
                        />
                      </li>
                      <li className='half full-mobile'>
                        {this.familyNameFieldError && (
                          <span className='error bottom'>
                            {this.familyNameFieldError}
                          </span>
                        )}
                        <div className="border"></div>
                        <input
                          id='familyName'
                          className='form-control'
                          type='text'
                          placeholder='Last Name'
                          value={this.state.familyName}
                          onChange={this.handleChange('familyName')}
                          autoComplete='family-name'
                        />
                      </li>
                      <li className='half full-mobile'>
                        {this.emailFieldError && (
                          <span className='error bottom'>{this.emailFieldError}</span>
                        )}
                        <div className="border"></div>
                        <input
                          id='email'
                          className='form-control'
                          type='email'
                          placeholder='Email'
                          value={this.state.email}
                          onChange={this.handleChange('email')}
                          autoComplete='email'
                        />
                      </li>
                      <li className='half full-mobile'>
                        {this.phoneNumberFieldError && (
                          <span className='error bottom'>{this.phoneNumberFieldError}</span>
                        )}
                        <div className="border"></div>
                        <input
                          type='tel'
                          placeholder='Phone Number'
                          value={this.state.phoneNumber}
                          onChange={this.handleChange('phoneNumber')}
                          autoComplete='tel-national'
                        />
                      </li>
                    </ul>
                    
                    <div className='container'>
                      <AddressChecker
                        callback={({
                          address,
                          city,
                          zip,
                          buildingSelected,
                          matchingBuilding,
                          calendarUrl
                        }) => {
                          this.setState({
                            addressFirst: address,
                            city,
                            zipCode: zip,
                            buildingSelected,
                            matchingBuilding,
                            calendarUrl
                          });
                        }}
                        reset={() => {
                          this.setState({
                            addressFirst: '',
                            city: '',
                            zipCode: '',
                            buildingSelected: false,
                            matchingBuilding: false,
                            calendarUrl: null
                          });
                        }}
                      />
                    </div>

                    {this.state.buildingSelected ? (
                      <div
                        style={{
                          display: this.state.matchingBuilding ? 'none' : 'block'
                        }}
                      >
                        <ul>
                          <li className='full'>
                            {this.addressFirstFieldError && (
                              <span className='error bottom'>
                                {this.addressFirstFieldError}
                              </span>
                            )}
                            <div className="border"></div>
                            <input
                              className='form-control'
                              type='text'
                              placeholder='Street Address'
                              value={this.state.addressFirst}
                              onChange={this.handleChange('addressFirst')}
                              autoComplete='address-line1'
                            />
                          </li>
                          <li className='full'>
                            <div className="border"></div>
                            <input
                              className={cx('form-control', {
                                highlight:
                                  this.state.addressFirst.length &&
                                  !this.state.addressSecond.length
                              })}
                              type='text'
                              placeholder='Address Line 2 / Apartment / Unit / Floor'
                              value={this.state.addressSecond}
                              onChange={this.handleChange('addressSecond')}
                              autoComplete='address-line2'
                            />
                          </li>
                        </ul>
                        <ul>
                          <li className='half'>
                            {this.cityFieldError && (
                              <span className='error bottom'>
                                {this.cityFieldError}
                              </span>
                            )}
                            <div className="border"></div>
                            <input
                              className='form-control'
                              type='text'
                              placeholder='City'
                              value={this.state.city}
                              onChange={this.handleChange('city')}
                              autoComplete='address-level2'
                            />
                          </li>
                          <li className='half'>
                            {this.usStateFieldError && (
                              <span className='error bottom'>
                                {this.usStateFieldError}
                              </span>
                            )}
                            <div className="border"></div>
                            <select
                              name='usState'
                              className='form-control'
                              value={this.state.usState}
                              onChange={this.handleChange('usState')}
                              autoComplete='address-level1'
                            >
                              <option value=''>Select a State</option>
                              <option value='AL'>Alabama</option>
                              <option value='AK'>Alaska</option>
                              <option value='AZ'>Arizona</option>
                              <option value='AR'>Arkansas</option>
                              <option value='CA'>California</option>
                              <option value='CO'>Colorado</option>
                              <option value='CT'>Connecticut</option>
                              <option value='DE'>Delaware</option>
                              <option value='DC'>District Of Columbia</option>
                              <option value='FL'>Florida</option>
                              <option value='GA'>Georgia</option>
                              <option value='HI'>Hawaii</option>
                              <option value='ID'>Idaho</option>
                              <option value='IL'>Illinois</option>
                              <option value='IN'>Indiana</option>
                              <option value='IA'>Iowa</option>
                              <option value='KS'>Kansas</option>
                              <option value='KY'>Kentucky</option>
                              <option value='LA'>Louisiana</option>
                              <option value='ME'>Maine</option>
                              <option value='MD'>Maryland</option>
                              <option value='MA'>Massachusetts</option>
                              <option value='MI'>Michigan</option>
                              <option value='MN'>Minnesota</option>
                              <option value='MS'>Mississippi</option>
                              <option value='MO'>Missouri</option>
                              <option value='MT'>Montana</option>
                              <option value='NE'>Nebraska</option>
                              <option value='NV'>Nevada</option>
                              <option value='NH'>New Hampshire</option>
                              <option value='NJ'>New Jersey</option>
                              <option value='NM'>New Mexico</option>
                              <option value='NY'>New York</option>
                              <option value='NC'>North Carolina</option>
                              <option value='ND'>North Dakota</option>
                              <option value='OH'>Ohio</option>
                              <option value='OK'>Oklahoma</option>
                              <option value='OR'>Oregon</option>
                              <option value='PA'>Pennsylvania</option>
                              <option value='RI'>Rhode Island</option>
                              <option value='SC'>South Carolina</option>
                              <option value='SD'>South Dakota</option>
                              <option value='TN'>Tennessee</option>
                              <option value='TX'>Texas</option>
                              <option value='UT'>Utah</option>
                              <option value='VT'>Vermont</option>
                              <option value='VA'>Virginia</option>
                              <option value='WA'>Washington</option>
                              <option value='WV'>West Virginia</option>
                              <option value='WI'>Wisconsin</option>
                              <option value='WY'>Wyoming</option>
                            </select>
                          </li>
                        </ul>
                        <ul>
                          <li className='half'>
                            {this.zipCodeFieldError && (
                              <span className='error bottom'>
                                {this.zipCodeFieldError}
                              </span>
                            )}
                            <div className="border"></div>
                            <input
                              className='form-control'
                              type='text'
                              placeholder='Postal / Zip Code'
                              value={this.state.zipCode}
                              onChange={this.handleChange('zipCode')}
                              autoComplete='postal-code'
                            />
                          </li>
                          <li className='half'>
                            {this.countryFieldError && (
                              <span className='error bottom'>
                                {this.countryFieldError}
                              </span>
                            )}
                            <div className="border"></div>
                            <input
                              className='form-control'
                              type='text'
                              placeholder='United States'
                              value={this.state.country}
                              onChange={this.handleChange('country')}
                              autoComplete='country'
                              disabled
                            />
                          </li>
                        </ul>
                        <ul>
                          <li className='full'>
                            <div className="borderArea"></div>
                            <textarea
                              id='notes'
                              className='form-control'
                              type='text'
                              placeholder='Notes'
                              value={this.state.notes}
                              onChange={this.handleChange('notes')}
                              rows='3'
                              autoComplete='off'
                            />
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  
                  {this.state.buildingSelected ? (
                    <button
                      className='btn btn-blue'
                      type='submit'
                      onClick={this.handlePreSubmit}
                    >
                      Send
                    </button>
                  ) : null}
                  </div>

                  {this.state.emailResponse === false && (
                    <span className='formError'>{this.state.message}</span>
                  )}
                </form>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default SignUpForm;
