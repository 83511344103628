import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import placeholder from '../../assets/images/placeholder.svg';
import '../../assets/scss/addressChecker.scss';


const AddressChecker = ({ callback, reset }) => {
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const isLongEnough = value && value.length >= 1;

  const fetchData = async newValue => {
    if (isLoading) {
      return;
    }
    
    try { 
        const result = await axios(`https://webapp.imagineinternet.net:8443/building/search?q=${newValue}`);
        //console.log('done');
        setLoading(false);
        setResults(result.data);
        
    } catch(e) {
        setLoading(false);
    }
  };

  const debounced = useRef(debounce(newValue => fetchData(newValue), 500));

  useEffect(() => {
    if (isLongEnough) {
      setLoading(true);
      debounced.current(value);
    } else {
      setLoading(false);
      setResults([]);
    }
  }, [value]);


  return (
    <ul className='address-checker'>
      <li>
        <h6 className='full'>Check my address for service:</h6>
      </li>
      <li className='full'>
        <div className="border"></div>
        <input
          className='form-control'
          type='text'
          placeholder='Enter your street address'
          value={value}
          onChange={event => setValue(event.target.value)}
         
          autoComplete='disableit'
        />
      </li>
      {value.length ? results.map(result => {
        return (
          <li
            key={result.id}
            className={cx('full', 'result', {
              selected: result.id === selected
            })}
            onClick={() => {
              setSelected(result.id);
              callback({
                address: result.address,
                city: result.city,
                zip: result.postal,
                buildingSelected: true,
                matchingBuilding: true,
                calendarUrl: result.url
              });
            }}
          >
            { result.imgurl ? (
              <img
                src={result.imgurl}
                height='100'
                width='150'
                alt={result.name}
              /> ) 
            :
              <img
                className='placeholder'
                src={placeholder}
                height='100'
                width='150'
                alt={result.name}
              />
            }
            <div className='details'>
              <h6>{result.name}</h6>
              <p>{result.address}, {result.city}, {result.state}{' '}
              {result.postal}</p>
            </div>
            {selected === result.id && (
              <div
                className='delete'
                onClick={event => {
                  event.stopPropagation();
                  setSelected(null);
                  reset();
                }}
              >
                ×
              </div>
            )}
          </li>
        );
      }) : null}
      {!results.length && isLongEnough && !isLoading ? (
        <li
          className='full result'
          onClick={() => {
            setSelected(null);
            callback({
              address: '',
              city: '',
              zip: '',
              buildingSelected: true,
              matchingBuilding: false,
              calendarUrl: null
            });
          }}
        > 
          <div className='placeholder'>
            <img src={placeholder} height='69' width='120' alt='Request service' />
            <div className='details'>
              <h6>Request service at my address</h6>
            </div>
          </div>
        </li>
      ) : null}
    </ul>
  );
};

export default AddressChecker;
