import React, { Component } from 'react';
import axios from 'axios';

class SupportForm extends Component {
  constructor(props) {
    super();

    this.state = {
      emailResponse: '',
      fullName: '',
      email: '',
      phone: '',
      description: '', 
      message: '',
      formError: []
    }
  }

  handleChange = name => event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  getFormError = formData => {
    const error = [];
    const { fullName, email, phone, description } = formData;
    const isEmailValid = email => /\S+@\S+\.\S+/.test(email);
    const isPhoneValid = phone => /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phone);
    
    if (!fullName.length) {
      error.push({
        key: 'fullName',
        error: 'Name cannot be empty.',
      });
    }

    if (!email.length) {
      error.push({
        key: 'email',
        error: 'Email cannot be empty.',
      });
    }

    if (!isEmailValid(email)) {
      error.push({
        key: 'email',
        error: 'Please enter a valid email address.',
      });
    }

    if (!phone.length) {
      error.push({
        key: 'phone',
        error: 'Phone number cannot be empty.',
      });
    }

    if (!isPhoneValid(phone)) {
      error.push({
        key: 'phone',
        error: 'Please check your phone number for typos.',
      })
    }

    if (!description.length) {
      error.push({
        key: 'description',
        error: 'Please tell us how we can help.',
      });
    }

    return error;
  };
  
  get fullNameFieldError() {
    const { formError } = this.state;
    const fullNameErrors = formError.filter(err => err.key === 'fullName');
    if (fullNameErrors.length) {
      return fullNameErrors[0].error;
    }

    return null;
  }

  get emailFieldError() {
    const { formError } = this.state;
    const emailErrors = formError.filter(err => err.key === 'email');
    if (emailErrors.length) {
      return emailErrors[0].error;
    }

    return null;
  }

  get phoneFieldError() {
    const { formError } = this.state;
    const phoneErrors = formError.filter(err => err.key === 'phone' );
    if (phoneErrors.length) {
      return phoneErrors[0].error;
    }

    return null;
  }

  get descriptionFieldError() {
    const { formError } = this.state;
    const descriptionErrors = formError.filter(err => err.key === 'description' );
    if (descriptionErrors.length) {
      return descriptionErrors[0].error;
    }

    return null;
  }

  handlePreSubmit = (event) => {
    event.preventDefault();
    const { fullName, email, phone, description } = this.state;
    const formData = { fullName, email, phone, description };
    const formError = this.getFormError(formData);  

    if (!formError.length) {
      this.setState({ formError: [] });
      this.handleSubmit(formData);
    } else {
      this.setState({ formError });
    }
  };

  async handleSubmit(formData) {
    const { fullName, email, phone, description } = formData;

    await axios.post('/api/mail', {
      fullName,
      email, 
      phone,
      description
    })
      .then(res => {
        this.setState({ emailResponse: res.data.success, message: res.data.message })
      })
      .catch (err => {
        console.error(err)
        //this.setState({ emailResponse: err.data.success, message: err.data.message })
      })
  };

  resetForm = () => {
    this.setState({
        ...this.state,
        fullName: '',
        email: '',
        phone: '',
        description: '', 
        formError: []
    })
  }

  render() {
    return (
      <div className="form-container">
        { this.state.emailResponse ?
          <div className="thank-you">
            <h3>Thank you for getting in touch!</h3>
            <p>One of our customer happiness members will be getting back to you as soon as possible.</p>
            <p>Have a great day! </p>
          </div>
        : 
          <form className="small-form">
            <h5>Our email is <a href="mailto:support@imagineinternet.net">support@imagineinternet.net</a> <br/>and you can also fill out this contact form.</h5>

            <div className="form">
              <ul>
                <li className="full">
                  { this.fullNameFieldError && 
                    <span className="error bottom">{ this.fullNameFieldError }</span>
                  }
                  <div className="border"></div>
                  <input
                      id="fullName"
                      className="form-control"
                      type="text"
                      placeholder="Full Name"
                      value={this.state.fullName}
                      onChange={this.handleChange('fullName')}
                      autoComplete="name"
                    />
                </li>
                <li className="full">
                  { this.emailFieldError && 
                    <span className="error bottom">{ this.emailFieldError }</span>
                  }
                  <div className="border"></div>
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    autoComplete="email"
                  />
                </li>
                <li className="full">
                  { this.phoneFieldError && 
                    <span className="error bottom">{ this.phoneFieldError }</span>
                  }
                  <div className="border"></div>
                  <input
                    id="phone"
                    className="form-control"
                    type="text"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={this.handleChange('phone')}
                    autoComplete="phone"
                  />
                </li>
                <li className="full">
                  { this.descriptionFieldError && 
                    <span className="error bottom">{ this.descriptionFieldError }</span>
                  }
                  <div className="borderArea"></div>
                  <textarea
                    id="description"
                    className="form-control"
                    type="text"
                    placeholder="Tell us how we can help"
                    value={this.state.description}
                    onChange={this.handleChange('description')}
                    rows="5"
                  />
                </li>
              </ul>
              
              <button
                className="btn btn-blue"
                type="submit"
                onClick={this.handlePreSubmit}
                >
                Send
              </button>

            </div>

            { this.state.emailResponse === false && 
              <span className="formError">{this.state.message}</span>
            }
          </form>
        }
      </div> 
    )
  }
}

export default SupportForm;
