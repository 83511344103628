import React, { Component } from 'react';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { DataScroll } from '../../utils/dataScroll';

import logo from '../../assets/images/logo.svg';


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(evt) {
    evt.preventDefault();
    
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  componentDidMount(){
    DataScroll();
  }
  
  render() {
    const { isOpen } = this.state;
    const { alwaysIn } = this.props;
    const showHideClassName = isOpen ? 'show' : 'hide';
    const alwaysInClassName = alwaysIn ? 'always-in' : '';

    return (
      <header className={`${alwaysInClassName}`} data-scroll data-scroll-enter="35">
        <div className="inner">
          <div className="left hide-mobile">
            <a className="btn-light rollover-link two" href="tel:+18889821220">CALL 1-888-982-1220</a>
          </div>
          <div className="center">
            <a href="/" className="logo">
              <img src={logo} alt="Imagine Internet" width="179px" height="95px" />
            </a>
          </div>
          <div className="right hide-mobile">
            <Link to="signup" className="btn small">Sign Up</Link>
          </div>

          <div className="right-menu">
            <div onClick={this.toggleMenu} className={['nav-toggle ' + showHideClassName]}>
              <a href="/"> </a>
            </div>
            
            <nav id="mainNavMobile" className={showHideClassName}>
              <div className="inner">
                <Button type="close" size="lg" styles="dark" onClick={this.toggleMenu}/>
                <ul className="nav-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li>
                    <Link to="signup">Sign up</Link>
                  </li> */}
                  <li>
                    <a href="https://portal.imagineinternet.net/" target="_blank" rel="noopener noreferrer">Login</a>
                  </li>
                  <li>
                    <Link to="support">Support</Link>
                  </li>
                  <li>
                    <Link to="policies">Policies</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
