import React from 'react';
import { Link } from 'react-router-dom';

const Button = (props) => {

  const { to, classn= '', children, type, size, styles } = props;


    switch (type) {
      case "close": 
        return (
          <div className={`close-btn ${size} ${styles}`} {...props}>
            <div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )
      case "main": 
        return (
          <Link to={to} className={`btn ${classn}`} {...props}>{ children }</Link>
        )
      case "external": 
        return (
          <a href={to} target="_blank" rel="noopener noreferrer" className={`btn btn-blue ${classn}`} {...props}>{ children }</a>
        )
      case "scroll":
        return ( 
          <a className={`btn btn-blue ${classn}`} {...props}>{ children }</a>
        )
      case "simple": 
        return (
          <a href={to} className={`btn btn-blue ${classn}`} {...props}>{ children }</a>
        )
      case "hyphen":
        return (
          <Link to={to} className={`hyphn-btn ${classn}`} {...props}><span></span>{ children }</Link>
        )
      default: 
        return (
          <a href={to} className="btn-simple" {...props}>{ children }</a>
        ) 
    }
}

export default Button;
