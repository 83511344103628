import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo2.svg';
import PrivacyPolicy from '../../assets/documents/ImagineInternet_PP_2019.pdf';
import AcceptableUse from '../../assets/documents/ImagineInternet_AUP_2019.pdf';
import NetworkDisclosure from '../../assets/documents/ImagineInternet_NMD_2019.pdf';
import TermsOfService from '../../assets/documents/ImagineInternet_TOS_2019.pdf';

const Footer = (props) => {
  return (
    <footer className={props.color}>
        <div className="footer-links">
          <div className="footer-content">
            <div className="footer-logo">
              <img src={logo} alt="Imagine Internet"  width="212px" height="99px" />
              <p>&copy; { (new Date().getFullYear()) } Best Internet Company, LLC.<br />All rights reserved.</p>
            </div>
            <div className="company">
              <ul>
                <li className="footer-header">Imagine Internet<hr /></li>
                <li><Link to="signup">Home</Link></li>
                <li><a href="https://portal.imagineinternet.net/" target="_blank" rel="noopener noreferrer">Login</a></li>
                {/* <li><Link to="signup">Sign up</Link></li> */}
                <li><Link to="support">Support</Link></li>
              </ul>
            </div>
            <div className="foot-policies">
              <ul>
                <li className="footer-header">Policies<hr /></li>
                <li>
                  <a href={AcceptableUse} target="_blank" rel="noopener noreferrer">Acceptable use</a>
                </li>
                <li>
                  <a href={NetworkDisclosure} target="_blank" rel="noopener noreferrer">Network disclosure</a>
                </li>
                <li>
                  <a href={PrivacyPolicy} rel="noopener noreferrer" target="_blank">Privacy policy</a>
                </li>
                <li>
                  <a href={TermsOfService} target="_blank" rel="noopener noreferrer">Terms of service</a>
                </li>
              </ul>
            </div>
            <div className="contact">
              <ul>
                <li className="footer-header">Contact us<hr /></li>
                <li><a className="rollover-link two" href="tel:+18889821220">1-888-982-1220</a></li>
                <li><a href="mailto:support@imagineinternet.net">support@imagineinternet.net</a></li>
                <li><br /><p>Imagine Internet<br />11 Broadway, Suite 632 <br /> New York, NY 10004</p></li>
              </ul>
            </div>
          </div>
          {/* <div className="footer-icon-links">
            <div className="footer-social-links">
              <div className="footer-list-header">Follow us</div>
              <div className="footer-social-links-buttons">
                <a className="footer-social-links-button instagram" target="_blank" rel="noopener noreferrer" href="https://instagram.com/">
                  <img src={instagram} width="30px" height="30px" alt="Instagram"/>
                </a>
                <a className="footer-social-links-button facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/">
                  <img src={facebook} width="30px" height="30px" alt="Facebook"/>
                </a>
                <a className="footer-social-links-button twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/">
                  <img src={twitter} width="30px" height="30px" alt="Twitter"/>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </footer>
  )
}

export default Footer;
