import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop.js';

import Home from './screens/Home';
import SignUp from './screens/SignUp';
import Support from './screens/Support';
import Policies from './screens/Policies';
import FourOhFour from './screens/404';

import '../src/assets/scss/index.scss';

const App = () => {
  return ( 
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route
            path="/"
            exact
            component={Home}
          />
          {/* <Route
            path="/signup"
            exact
            component={SignUp}
          /> */}
          <Route
            path="/support"
            exact
            component={Support}
          />
          <Route
            path="/policies"
            exact
            component={Policies}
          />
          <Route
            path="*"
            exact
            component={FourOhFour}
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;


