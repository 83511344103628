import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import '../../assets/scss/home.scss';


function Home() {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="main-content">
        <section className="intro">
          <div className="hns-section">
            <div className="inner">
              <h1>The internet you dream of!</h1>
              <h4>Fast, reliable, affordable, no contracts!</h4>
            </div>
          </div>
        </section>


        <section className="speeds">
          <div className="hns-section">
            <div className="inner">
              <h5>Up to the sky</h5>
              <h2>Fast speeds!</h2>
              <hr />
              <p>Up to</p>
              <div className="speed">
                <span className="left">
                  <span className="tilde">~</span>500<small>Mbps</small>
                  <span className="updown">Upload</span>
                </span>
                <span className="right">
                  <span className="tilde">~</span>500<small>Mbps</small>
                  <span className="updown">Download</span>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="service">
          <div className="hns-section">
            <div className="inner">
              <h5>We want to make you happy</h5>
              <h2>Friendly customer service</h2>
              <hr />
              <p>You can call or email us 24/7/365 and talk to a human being who is excited to make sure you are taken care of.</p>
            </div>
          </div>
        </section>

        <section className="happiness">
          <div className="hns-section">
            <div className="inner">
              <h5>Our customer happiness policy</h5>
              <h2>Will keep you in <br />zen mode</h2>
              <hr />
              <p>The Imagine Internet Network only works if your customers are happy.</p>
            </div>
          </div>
        </section>
      </div>

      <Footer color="light"/>

    </div>
  );
}

export default Home;
