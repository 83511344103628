export function DataScroll() {
  document.addEventListener('scroll', () => {
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    const bottom = document.documentElement.scrollHeight || document.body.scrollHeight;

    const stickies = document.querySelectorAll('[data-scroll]');
    
    [].forEach.call(stickies, (scroll) => {
      const scrollInitial = parseInt(scroll.getAttribute('data-scroll-initial'), 10);
      const scrollEnter = parseInt(scroll.getAttribute('data-scroll-enter'), 10) || scrollInitial;
      const scrollExit = parseInt(scroll.getAttribute('data-scroll-exit'), 10) || bottom;

      if (top >= scrollEnter && top <= scrollExit) {
        scroll.classList.add('scrolled-in');
        scroll.classList.remove('scrolled-out');
      } else {
        scroll.classList.remove('scrolled-in')
        scroll.classList.add('scrolled-out');
      }
    });
  });
}
