import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import boat from '../../assets/images/404.svg';


import '../../assets/scss/404.scss';

const FourOhFour = () => (
  <div id="main-wrapper" className="FourOhFour">
    <Header />
    <div className="main-content">
      <section className="hns-section">
        <div className="inner">
          <p>Uh oh, you're lost!</p>
          <h1>Page not found</h1>
          <hr />
          <h5>Let us help you find your way back!</h5>
          
          <img src={boat} alt="Lost boat" width="635" height="326" />

          <Button type="main" classn="btn-light" to="/">Go back home</Button>
         
          
        </div>
      </section>
    </div>
    <Footer color="light"/>
  </div>
);

export default FourOhFour;
